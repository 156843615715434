<template>
  <div
    class="ConfirmView">
    <HeaderStep
      :logo="flow?.headerLogo"
      :theme="currentStep.headerTheme"
      :title="$t('flows.shared.steps.confirm.title')"
      :subtitle="$t(
        'flows.shared.steps.confirm.subtitle',
        { email: session.email.value },
      )"
    />

    <div class="container">
      <p
        class="__label">
        {{ $t('shared.confirmationCode') }}
      </p>

      <InputCode
        v-model="session.code.value"
        :code-length="codeLength"
        :is-disabled="isSubmittingCode || isCodeValid || isSubmittingEmail"
        ref="inputCode"
      />

      <div
        class="__status">
        <ErrorMessage
          v-if="error"
          class="__error"
          key="error-message"
        >
          {{ error }}
        </ErrorMessage>

        <div
          v-if="message"
          class="__message"
          key="message"
        >
          {{ message }}
        </div>

        <LoaderBase
          class="__loader"
          v-show="isSubmittingCode || isCodeValid || isSubmittingEmail || isResentMessage"
          :is-checkmark="isCodeValid || isResentMessage"
        />
      </div>

      <div
        class="__helpers">
        <p
          class="__helper">
          {{ $t('shared.notReceivedEmail') }}

          <span v-if="isResendAttemptsExceeded" v-html="$t('shared.contactForAssistance')"></span>

          <template v-else>
            {{ $t('shared.checkSpam') }}
            <button
              class="color-link-blue nowrap link"
              :disabled="isResendAttemptsExceeded"
              @click="handleResend"
            >
              {{ $t('shared.clickToResend') }}
            </button>
            <span v-show="resendAttempts >= 1">
            &thinsp;{{ $tc(
              'shared.resendsRemaining',
              maxResendAttempts - resendAttempts,
              { attempts: maxResendAttempts - resendAttempts }
            )}}
            </span>.
          </template>
        </p>

        <p
          class="mt-1 __helper">
          {{ $t('shared.wrongAddress', { email: session.email.value }) }}
          <router-link
            class="color-link-blue nowrap link"
            :to="{
              name: 'step',
              params: {
                lang: this.$route.params.lang || '',
                flowName: $route.params.flowName,
                stepName: 'email',
              },
            }">
            {{ $t('shared.changeEmail') }}
          </router-link>
        </p>
      </div>
    </div>

  </div>
</template>


<script>
import useEmail from '@/use/email';
import HeaderStep from '@/components/HeaderStep.vue';
import InputCode from '@/components/InputCode.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import LoaderBase from '@/components/LoaderBase.vue';

export default {
  name: 'ConfirmView',

  components: {
    HeaderStep,
    InputCode,
    ErrorMessage,
    LoaderBase,
  },

  inject: [
    'flow',
    'session',
    'nextStep',
    'currentStep',
    'saveSession',
    'trackEvent',
  ],

  setup() {
    const {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    } = useEmail();

    return {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    };
  },

  data() {
    return {
      attemptedCodes: [],
      codeLength: 6,
      error: null,
      isCodeValid: false,
      isResentMessage: false,
      isSubmittingCode: false,
      maxResendAttempts: 3,
      message: null,
      resendAttempts: 0,
    };
  },

  computed: {
    codeAsString() {
      return this.session.code.value.join('');
    },

    isResendAttemptsExceeded() {
      return this.resendAttempts >= this.maxResendAttempts;
    },
  },

  watch: {
    codeAsString(newCode) {
      if (newCode.length >= this.codeLength) {
        this.handleSubmit();
      }
    },
  },

  mounted() {
    setTimeout(() => this.$refs.inputCode.focus(), 100);

    if (window.intellimize) {
      window.intellimize.activate();
    }
  },

  methods: {
    async handleSubmit() {
      if (this.isSubmittingEmail || this.isSubmittingCode) return;

      this.error = null;
      this.isSubmittingCode = true;

      const isCodeValid = !this.isCodeAttempted(this.codeAsString)
        && await this.validateCode(this.codeAsString);

      setTimeout(() => {
        this.isCodeValid = isCodeValid;

        this.isSubmittingCode = false;

        if (!isCodeValid) {
          this.error = this.$t('shared.confirmationCodeError', { email: this.session.email.value });
          return setTimeout(() => this.$refs.inputCode.empty(), 10);
        }

        this.trackEvent('entered confirmation code', this.currentStep, this.session);

        this.saveSession();

        return setTimeout(() => {
          this.$router.push({
            name: 'step',
            params: {
              lang: this.$route.params.lang || '',
              flowName: this.$route.params.flowName,
              stepName: this.nextStep.routeName,
            },
            query: this.$route.query,
          });
        }, 500);
      }, 1500);
    },

    async validateCode(code) {
      try {
        const { data } = await this.$http.post(`/api/free/v2/code/status?code=${code}&email=${this.session.email.value}`);

        const accessToken = data.access_token || null;

        if (accessToken) {
          localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN_NAME, accessToken);
        }

        return !!accessToken;
      } catch (err) {
        this.attemptedCodes.push(code);

        const isRateLimitError = [429].includes(err.response?.status);

        if (isRateLimitError) {
          this.error = this.$t('shared.rateLimitError');
        }

        return false;
      }
    },

    async handleResend() {
      if (
        this.isResendAttemptsExceeded
        || this.isSubmittingEmail
        || this.isSubmittingCode
      ) return;

      this.error = null;
      this.message = this.$t('shared.resendingCode');

      this.submitEmail({
        isRetry: true,
        flowName: this.$route.params.flowName,
        session: this.session,
        onSuccess: this.handleRetrySuccess,
        onError: this.handleRetryError,
      });
    },

    handleRetrySuccess() {
      this.resendAttempts += 1;
      this.message = this.$t('shared.resentCode');
      this.isResentMessage = true;
      this.trackEvent('resend confirmation code', this.currentStep, this.session);
      setTimeout(() => {
        this.isResentMessage = false;
        this.message = null;
      }, 2000);
    },

    handleRetryError() {
      this.resendAttempts = this.maxResendAttempts;
      this.message = null;
      this.error = this.$t('shared.resendError');
    },

    isCodeAttempted(code) {
      return this.attemptedCodes.includes(code);
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.ConfirmView {
  padding-bottom: 80px;
  text-align: center;
}

.__label {
  padding-bottom: 16px;
  font-weight: $font-weight-bold;
}

.__status {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.__error {
  margin: 6px auto;
  max-width: 296px;
}
.__message {
  font-size: 14px;
  margin: 6px auto;
  margin-bottom: -8px;
  max-width: 296px;
}

.__loader {
  margin-top: 16px;
}

.__helpers {
  margin-top: 96px;
}

.__helper {
  font-size: 14px;
}
</style>
