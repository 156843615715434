<template>
  <div
    class="InputCode"
    @paste="handlePaste">
    <input
      class="__input"
      v-for="(digit, digitIndex) in codeLength"
      type="tel"
      maxlength="1"
      data-pattern="[0-9]*"
      inputmode="numeric"
      autocomplete="off"
      :value="modelValue[digitIndex]"
      :disabled="isDisabled"
      :key="`input-${digit}`"
      :ref="setInputRef"
      @keydown="handleKeyDown(digitIndex, $event)"
      @focus="handleFocus(digitIndex, $event)"
      @input="handleInput(digitIndex, $event)"
    />
  </div>
</template>


<script>
export default {
  name: 'InputCode',

  data() {
    return {
      inputRefs: [],
    };
  },

  props: {
    codeLength: {
      type: Number,
      default: 6,
    },
    modelValue: {
      type: Array,
      default: (props) => Array(props.codeLength).fill(''),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    'update:modelValue',
  ],

  beforeUpdate() {
    this.inputRefs = [];
  },

  methods: {
    handleKeyDown(digitIndex, e) {
      switch (e.key) {
      case 'ArrowLeft':
        e.preventDefault();
        this.selectDigit(digitIndex - 1);
        break;
      case 'ArrowRight':
        e.preventDefault();
        this.selectDigit(digitIndex + 1);
        break;
      case 'Backspace':
        e.preventDefault();
        this.handleBackspace(digitIndex);
        break;
      default:
        break;
      }
    },

    handleFocus(digitIndex, e) {
      e.target.select(e);
    },

    handleInput(digitIndex, e) {
      const persistedValue = this.persistDigit(e.target.value);

      this.setValue(digitIndex, persistedValue);

      if (persistedValue) this.selectDigit(digitIndex + 1);
    },

    handleBackspace(digitIndex) {
      const currentDigitHasValue = !!this.modelValue[digitIndex];
      const hasPrecedingDigit = digitIndex > 0;

      if (currentDigitHasValue) {
        this.setValue(digitIndex, '');
      } else if (hasPrecedingDigit) {
        this.setValue(digitIndex - 1, '');
        this.selectDigit(digitIndex - 1);
      }
    },

    setValue(index, value) {
      const newModelValue = [...this.modelValue];

      newModelValue[index] = value;

      this.$emit('update:modelValue', newModelValue);
    },

    selectDigit(digitIndex) {
      if (digitIndex < 0 || digitIndex >= this.codeLength) return;

      this.inputRefs[digitIndex].select();
    },

    handlePaste(e) {
      e.stopPropagation();
      e.preventDefault();

      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text');

      const pastedValue = this.persistDigit(pastedData).split('');

      const newModelValue = [...this.modelValue];

      pastedValue
        .slice(0, this.codeLength)
        .forEach((value, i) => { newModelValue[i] = value; });

      this.$emit('update:modelValue', newModelValue);
    },

    persistDigit(value) {
      // Alternative regex: /[^\d]/gi
      return value.replace(/\D+/g, '');
    },

    setInputRef(el) {
      if (el) this.inputRefs.push(el);
    },

    focus() {
      this.inputRefs[0].focus();
    },

    empty() {
      this.$emit('update:modelValue', []);
      this.focus();
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.InputCode {
  white-space: nowrap;
}

.__input {
  display: inline-block;
  width: 50px;
  height: 64px;
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  border: 1px solid $border-color;
  border-right: none;
  border-radius: 0;
  caret-color: $dark;
  &:focus {
    border-color: $dark !important;
    & + .__input {
      border-left-color: $dark;
    }
  }
  &:first-child {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }

  &:last-child {
    border-right: 1px solid $border-color;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}
</style>
